import PropTypes from "prop-types";
import { toast } from "react-toastify";

const Toast = ({ type, message }) => {
  toast[type](
    <div className="d-flex align-items-center">
      <h6 className="mb-0">{message}</h6>
    </div>
  );
  return null;
};

Toast.propTypes = {
  type: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
};

Toast.dismiss = toast.dismiss;

export default Toast;
