import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation();
  const selectedLanguage = localStorage.getItem("i18nextLng");

  return (
    <footer
      className="footer bg-white"
      style={{ fontFamily: "Inter", fontWeight: "400" }}
    >
      <div className="row justify-content-center mb-3">
        <div className="mb-2">
          <a
            className="font-weight-bold text-simbalotto-orange"
            href={`https://jackpotlotteryonline.com${
              selectedLanguage === "en" ? "" : `/${selectedLanguage}`
            }`}
            rel="noopener noreferrer"
          >
            {t("footer.home")}
          </a>
        </div>

        <div className="mb-2 ml-2">
          <a
            className="font-weight-bold text-simbalotto-orange"
            href={`https://jackpotlotteryonline.com${
              selectedLanguage === "en" ? "" : `/${selectedLanguage}`
            }/terms-and-conditions/`}
            rel="noopener noreferrer"
          >
            {t("footer.terms-and-conditions")}
          </a>
        </div>

        <div className="mb-2 ml-2">
          <a
            className="font-weight-bold text-simbalotto-orange"
            href={`https://jackpotlotteryonline.com${
              selectedLanguage === "en" ? "" : `/${selectedLanguage}`
            }/privacy-policy/`}
            rel="noopener noreferrer"
          >
            {t("footer.privacy-policy")}
          </a>
        </div>

        <div className="mb-2 ml-2">
          <a
            className="font-weight-bold text-simbalotto-orange"
            href={`https://jackpotlotteryonline.com${
              selectedLanguage === "en" ? "" : `/${selectedLanguage}`
            }/faq`}
            rel="noopener noreferrer"
          >
            {t("footer.help")}
          </a>
        </div>

        <div className="mb-2 ml-2">
          <a
            className="font-weight-bold text-simbalotto-orange"
            href="https://affiliates.jackpotlotteryonline.com"
            rel="noopener noreferrer"
          >
            {t("footer.afiliate-program")}
          </a>
        </div>
      </div>

      <div className="row mb-3">
        <div className="col-12 col-md-12">
          <div className="text-center text-dark">
            <p
              className="m-0"
              dangerouslySetInnerHTML={{
                __html: t("footer.text-2", {
                  interpolation: { escapeValue: false },
                }),
              }}
            />
          </div>
        </div>
      </div>

      <div className="row align-items-center">
        <div className="col-12 text-center">
          <p className="m-0 text-dark">
            All rights reserved {new Date().getFullYear()}.®
            Jackpotlotteryonline
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
