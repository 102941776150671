import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useDocumentTitle from "../../useDocumentTitle";
import React, { useEffect, useCallback, useState } from "react";

const SimbalottoStatus = () => {
  const [respCode, setRespCode] = useState(null);
  const [respMessage, setRespMessage] = useState(null);
  const { t } = useTranslation();
  const history = useHistory();
  useDocumentTitle(`${t("site-name")}`);
  const urlParams = new URLSearchParams(window.location.search);

  const txnRef = urlParams.get("txnRef");

  if (!txnRef) {
    history.push("/");
  }

  const submitTxnRef = useCallback(async () => {
    const response = await fetch("/api/v1/simbalotto-gw/status", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ txnRef }),
    });

    if (!response.ok) {
      return;
    }

    const data = await response.json();
    setRespCode(data.code);
    const msg = t(`sv-errors.${data.code}`);
    setRespMessage(msg);
  }, [t, txnRef]);

  useEffect(() => {
    submitTxnRef();

    const timer = setTimeout(() => {
      history.push("/");
    }, 7000);

    return () => clearTimeout(timer);
  }, [history, submitTxnRef, txnRef]);

  return (
    <div className="row justify-content-center">
      <div className="col-12 col-md-5">
        <div className="card">
          <div className="card-body">
            <div className="text-center m-3">
              {respCode === "SG003" ? (
                <i className="fas fa-exclamation-triangle text-danger fa-5x"></i>
              ) : (
                <i className="fas fa-check-circle text-success fa-5x"></i>
              )}
            </div>
            <p
              className="text-dark text-center"
              style={{
                fontSize: "1rem",
                fontWeight: "bold",
              }}
            >
              {respMessage}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SimbalottoStatus;
